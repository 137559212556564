@import './vars.scss';


h2 {
    border-radius: 0 25px 25px 0;
    color: $cream;
    font-size: 2.5rem;
    margin-top: 2rem !important;
}
h3 {
    border-bottom: 4px solid $cream;
    border-radius: 5px;
    color: $blue;
    font-size: 2rem;
    text-align: center;
}

.box-container {
    background-color: #fff;
    background-color: $background-white;
    border: 1px solid #ccc;
    border-radius: 25px;
    padding: 1rem;
}

// @media screen and (min-width: 768px) {
//     h2 {
//         background: $linear-gradient;
//         border-radius: 25px;
//     }
// }